exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advanced-search-tsx": () => import("./../../../src/pages/advanced-search.tsx" /* webpackChunkName: "component---src-pages-advanced-search-tsx" */),
  "component---src-pages-bulk-tsx": () => import("./../../../src/pages/bulk.tsx" /* webpackChunkName: "component---src-pages-bulk-tsx" */),
  "component---src-pages-dsl-tsx": () => import("./../../../src/pages/dsl.tsx" /* webpackChunkName: "component---src-pages-dsl-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-personalization-tsx": () => import("./../../../src/pages/personalization.tsx" /* webpackChunkName: "component---src-pages-personalization-tsx" */),
  "component---src-pages-transaction-rules-tsx": () => import("./../../../src/pages/transaction-rules.tsx" /* webpackChunkName: "component---src-pages-transaction-rules-tsx" */)
}

