import { HeadFC } from "gatsby";
import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.scss";
import "./background.css";

const darkTheme = createTheme({
  palette: {
    // The background is a darker color, so we want to use the `dark` mode palette
    mode: "dark",
    // Spade colors!
    background: { default: "rgb(90, 92, 246)" },
  },
});

export const ApiKeyContext = React.createContext({
  apiKey: "",
  setApiKey: (_: string) => {},
});

const RootLayout = ({ children }: { children: React.ReactNode }) => {
  const [apiKey, setApiKey] = React.useState("");

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <Container component="main" maxWidth={false} className="flex col">
        <div
          className="flex col"
          style={{
            height: "100vh",
            justifyContent: "space-evenly",
          }}
        >
          <div
            className="flex"
            style={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "end",
            }}
          >
            <Link to="/">
              <StaticImage
                src="../images/logo_light.png"
                alt="The Spade Logo"
                height={50}
              />
            </Link>

            <div>
              <Link
                to="/transaction-rules"
                style={{ marginRight: "2rem", color: "white" }}
              >
                Transaction Rules
              </Link>
              <Link
                to="/advanced-search"
                style={{ marginRight: "2rem", color: "white" }}
              >
                Advanced Search
              </Link>
              <Link
                to="/personalization"
                style={{ marginRight: "2rem", color: "white" }}
              >
                Personalization
              </Link>
              <button
                className="api-documentation-button"
                onClick={() => {
                  window.open("https://docs.spade.com", "_blank");
                }}
              >
                API Documentation
              </button>
            </div>
          </div>
          <ApiKeyContext.Provider value={{ apiKey, setApiKey }}>
            {children}
          </ApiKeyContext.Provider>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default RootLayout;

export const Head: HeadFC = () => <title>Spade</title>;
